<template>
  <v-footer :height="$vuetify.breakpoint.mdAndUp ? '100' : '250'" color="#000000" padless proeminent>

    <div v-if="$vuetify.breakpoint.mdAndUp " class="d-inline-flex mx-auto justify-center">

      <div class="mr-8">
        <p class="txt_titulo">Endereço:</p>
        <p class="txt_endereco">Rua Beneditinos, 18/sobreloja</p>
        <p class="txt_endereco">Centro - Rio de Janeiro - RJ</p>

      </div>

      <div class="mr-8">
        <p class="txt_titulo">Telefone:</p>
        <p class="txt_endereco">(21) 96543-2308</p>

      </div>

      <div class="d-inline-flex ml -15 mb-8 justify-space-between">

        <v-btn
            class="mr-5"
            icon
            text
            x-large
            @click="insta"
        >
          <v-icon
              color="#fafafa"
              x-large>
            mdi-instagram
          </v-icon>
        </v-btn>

        <v-btn
            class="mr-5"
            icon
            text
            x-large
            @click="fb">
          <v-icon
              color="#fafafa"
              x-large>
            mdi-facebook
          </v-icon>
        </v-btn>

<!--        <v-btn-->
<!--            class="mr-5"-->
<!--            icon-->
<!--            text-->
<!--            x-large-->
<!--            @click="ld">-->
<!--          <v-icon-->
<!--              color="#fafafa"-->
<!--              x-large>-->
<!--            mdi-linkedin-->
<!--          </v-icon>-->
<!--        </v-btn>-->

      </div>

    </div>

    <div v-else class="d-flex flex-column mx-auto justify-center">

      <div class="d-inline-flex ml -15 mb-8 justify-center">

        <v-btn
            class="mr-5"
            icon
            text
            x-large
            @click="insta"
        >
          <v-icon
              color="#fafafa"
              x-large>
            mdi-instagram
          </v-icon>
        </v-btn>

        <v-btn
            class="mr-5"
            icon
            text
            x-large
            @click="fb">
          <v-icon
              color="#fafafa"
              x-large>
            mdi-facebook
          </v-icon>
        </v-btn>

<!--        <v-btn-->
<!--            class="mr-5"-->
<!--            icon-->
<!--            text-->
<!--            x-large-->
<!--            @click="ld">-->
<!--          <v-icon-->
<!--              color="#fafafa"-->
<!--              x-large>-->
<!--            mdi-linkedin-->
<!--          </v-icon>-->
<!--        </v-btn>-->

      </div>

      <div class="mr-8">
        <p class="txt_titulo">Telefone:</p>
        <p class="txt_endereco">(21) 96543-2308</p>

      </div>

      <div class="mr-8">
        <p class="txt_titulo">Endereço:</p>
        <p class="txt_endereco">Rua Beneditinos, 18/sobreloja</p>
        <p class="txt_endereco">Centro - Rio de Janeiro - RJ</p>

      </div>



    </div>


  </v-footer>
</template>

<script>
export default {
  name: "Footer.vue",
  methods: {



    insta() {
      window.location.href = 'https://www.instagram.com/digitalstormbr/';
    },

    fb() {
      window.location.href = 'https://www.facebook.com/digitalstormrj';
    },

    // ld() {
    //   window.location.href = 'https://www.facebook.com/medassurebrasil';
    // },
  }
}
</script>

<style scoped>
.txt_endereco {
  font-family: PoppinsELight;
  font-size: 1rem;
  color: #FFFFFF;
  line-height: 4px;
}

.txt_titulo {
  font-family: PoppinsEBold;
  font-size: 1rem;
  color: #FFFFFF;
}

</style>
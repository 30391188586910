var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"altura_relativa"},[_c('div',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'textoPacotes text-center' : 'textoPacotesCelular text-center'},[_c('p',[_vm._v("PACOTES")])]),_c('div',{staticClass:"d-flex justify-center mt-10"},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{},[_c('tr',[_c('th',[_vm._v(" Pacotes ")]),_c('th',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"elevation":"","height":"50","rounded":"","text":"","width":"200"}},'v-btn',attrs,false),on),[_c('span',{staticClass:"textoBotao"},[_vm._v("BRONZE ")])])]}}])},[_c('span',[_vm._v("Nosso pacote mais básico, voltado a empresas que queiram ativar ou fortalecer sua comunicação digital de forma profissional.")])])],1),_c('th',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"elevation":"","height":"50","rounded":"","text":"","width":"200"}},'v-btn',attrs,false),on),[_c('span',{staticClass:"textoBotao"},[_vm._v("PRATA ")])])]}}])},[_c('span',[_vm._v("Bottom tooltip")])])],1),_c('th',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-4 ml-3",attrs:{"elevation":"","height":"50","rounded":"","text":"","width":"200"}},'v-btn',attrs,false),on),[_c('span',{staticClass:"textoBotao"},[_vm._v(" OURO ")])])]}}])},[_c('span',[_vm._v("Bottom tooltip")])])],1)])]),_c('thead',[_c('tr',[_c('th',[_c('v-tooltip',{staticClass:"d-flex",attrs:{"max-width":"250","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" PRAZO SUGERIDO ")])]}}])},[_c('span',[_vm._v("Período suficiente para que o cliente possa avaliar os benefícios atingidos e decidir se pretende recontratar ou se já é hora de um plano maior.")])])],1),_c('th',{staticClass:"text-center"},[_vm._v(" 3 MESES ")]),_c('th',{staticClass:"text-center"},[_vm._v(" 6 MESES ")]),_c('th',{staticClass:"text-center"},[_vm._v(" 12 MESES ")])])]),_c('tbody',_vm._l((_vm.servicos),function(item){return _c('tr',{key:item.name},[_c('td',{},[_vm._v(_vm._s(item.name)+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-n6 ml-1",attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v("Tooltip")])])],1),(item.icon1)?_c('td',{staticClass:"text-center"},[_c('v-icon',{attrs:{"color":item.colorIcon1}},[_vm._v(" "+_vm._s(item.icon1))])],1):_vm._e(),(item.icon2)?_c('td',{staticClass:"text-center"},[_c('v-icon',{attrs:{"color":item.colorIcon2}},[_vm._v(" "+_vm._s(item.icon2))])],1):_vm._e(),(item.icon3)?_c('td',{staticClass:"text-center"},[_c('v-icon',{attrs:{"color":item.colorIcon3}},[_vm._v(_vm._s(item.icon3))])],1):_vm._e(),(item.name1)?_c('td',{staticClass:"text-center"},[_c('p',[_vm._v(" "+_vm._s(item.name1))])]):_vm._e(),(item.name2)?_c('td',{staticClass:"text-center"},[_c('p',[_vm._v(" "+_vm._s(item.name2))])]):_vm._e(),(item.name3)?_c('td',{staticClass:"text-center"},[_c('p',{},[_vm._v(" "+_vm._s(item.name3))])]):_vm._e()])}),0)]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div :class="$vuetify.breakpoint.mdAndUp ? 'altura_relativa' : 'altura_relativa_Cel'">
    <div :class="$vuetify.breakpoint.mdAndUp ? 'textoCliente text-center' : 'textoClienteCelular text-center'">
      <p>CLIENTES</p>
    </div>
    <!--   https://kasia.website/index.php/2021/01/15/responsive-vuetify-carousel-with-multiple-items-per-slide/ -->

    <section>
      <v-carousel class="d-flex justify-center align-center" continuous cycle hide-delimiters>
        <template v-for="(item, index) in clientes">
          <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1"
                           :key="index"
          >
            <v-row class="flex-nowrap" style="height:100%">
              <template v-for="(n,i) in columns">
                <template v-if="(+index + i) < clientes.length">
                  <v-col :key="i">
                    <v-card v-if="(+index + i) < clientes.length"
                            :class="$vuetify.breakpoint.mdAndUp ? 'img_Cliente' : 'img_Cliente_Cel'"
                            flat
                    >
                      <v-img :src="clientes[+index + i].src"></v-img>

                    </v-card>
                  </v-col>
                </template>
              </template>
            </v-row>
          </v-carousel-item>
        </template>
      </v-carousel>
    </section>


  </div>

</template>

<script>
export default {
  name: "Clientes_orig.vue",
  data() {
    return {
      clientes: [
        {
          src: require('../assets/AP.png'),
        },
        {
          src: require('../assets/BC.png'),
        },
        {
          src: require('../assets/CV.png'),
        },
        {
          src: require('../assets/MS.png'),
        },
      ],
    }
  },
  computed: {
    columns() {

      if (this.$vuetify.breakpoint.mdAndUp) {
        return 2;
      }

      return 1;
    }
  },
}
</script>

<style scoped>

.altura_relativa {
  height: calc(100vh - 130px);
}
.altura_relativa_Cel{
  height: calc(90vh - 130px);
}

.img_Cliente {
  width: 28vw;
  margin-left: 30px;
  margin-right: 30px;
}

.img_Cliente_Cel {
  width: 95vw;
  display: flex;
  justify-items: center;
}


.textoCliente {
  font-family: PoppinsEBold;
  font-size: 2rem;
  font-weight: lighter;
  color: #000000;
  margin-top: 50px;
  text-decoration-line: underline;
  text-decoration-style: double;
  text-decoration-color: #FFCE08;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
}

.textoClienteCelular {
  font-family: PoppinsELight;
  font-size: 2rem;
  color: #000000;
  margin-top: 50px;
  text-decoration-line: underline;
  text-decoration-style: double;
  text-decoration-color: #FFCE08;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
}
</style>
<template>

  <div class="$vuetify.breakpoint.mdAndUp ? 'altura-relativa' : 'altura_relativa_cel">

    <div :class="$vuetify.breakpoint.mdAndUp ? 'textoAndamos text-center' : 'textoAndamosCelular text-center'">
      <p>POR ONDE ANDA NOSSO TRABALHO</p>
    </div>
    <v-container class="d-flex justify-center mt-n2">

      <v-card color="transparent" outlined class="d-flex justify-center">
        <v-container class="d-flex justify-center">
          <v-row class="d-flex justify-center">
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
              <v-card :class="$vuetify.breakpoint.mdAndUp ? 'rio' : 'rioCel'" elevation="20">
<!--                <v-img class="align-end" src="../assets/rj.jpg">-->
                  <p class="tituloQuadrado mr-5 mb-n2">RIO DE JANEIRO</p>
                  <p class="textoQuadrado mr-5">Brasil</p>
<!--                </v-img>-->
              </v-card>
            </v-col>

            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
              <v-card :class="$vuetify.breakpoint.mdAndUp ? 'berlim align-center' : 'berlimCel align-center'" elevation="20">
<!--                <v-img class="align-end" src="../assets/rj.jpg">-->
                  <p class="tituloQuadrado mr-5 mb-n2">BERLIM</p>
                  <p class="textoQuadrado mr-5">Alemanha</p>
<!--                </v-img>-->
              </v-card>
            </v-col>

            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
              <v-card :class="$vuetify.breakpoint.mdAndUp ? 'luanda align-center' : 'luandaCel align-center'" elevation="20">
<!--                <v-img class="altura_foto" src="../assets/luanda.jpg">-->
                  <p class="tituloQuadrado mr-5 mb-n2">LUANDA</p>
                  <p class="textoQuadrado mr-5">Angola</p>
<!--                </v-img>-->
              </v-card>
            </v-col>


          </v-row>

        </v-container>
      </v-card>
    </v-container>
  </div>
</template>


<script>
export default {
  name: "OAndamos"
}
</script>

<style scoped>

.altura_relativa {
  height: calc(250vh - 130px);

}

.luanda {
  height: calc((100vh) / 1.6);
  width: 320px;
  border-radius: 15px;
  background-image: url("../assets/luanda2.png");
  background-size:cover;
}

.berlim{
  height: calc((100vh) / 1.6);
  width: 320px;
  border-radius: 15px;
  background-image: url("../assets/berlim2.png");
  background-size:cover;
}

.rio {
  height: calc((100vh) / 1.6);
  width: 320px;
  border-radius: 15px;
  background-image: url("../assets/rj2.png");
  background-size:cover;
}

.luandaCel {
  height: calc((100vh) / 3);
  width: 100%;
  min-width: 320px;
  border-radius: 15px;
  background-image: url("../assets/luanda2.png");
  background-size:cover;
}

.berlimCel{
  height: calc((100vh) / 3);
  width: 100%;
  min-width: 320px;
  border-radius: 15px;
  background-image: url("../assets/berlim2.png");
  background-size:cover;
}

.rioCel {
  height: calc((100vh) / 3);
  width: 100%;
  min-width: 320px;
  border-radius: 15px;
  background-image: url("../assets/rj2.png");
  background-size:cover;
}

.altura_foto {

  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 200%;

}
.icone {

  height: auto;
  margin: auto;
  width: 30%;
}

.textoAndamos {
  font-family: PoppinsEBold;
  font-size: 2rem;
  font-weight: lighter;
  color: #000000;
  margin-top: 50px;
  text-decoration-line: underline;
  text-decoration-style: double;
  text-decoration-color: #FFCE08;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
}

.textoAndamosCelular {
  font-family: PoppinsELight;
  font-size: 2rem;
  color: #000000;
  margin-top: 50px;
  text-decoration-line: underline;
  text-decoration-style: double;
  text-decoration-color: #FFCE08;
  text-decoration-thickness: 3px;
}
.textoQuadrado {
  font-family: PoppinsReg;
  font-size: 30px;
  color: #FFFFFF;
  text-align: right;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  position: absolute;
  bottom: 0px;
  right: 16px;

}

.tituloQuadrado {
  font-family: PoppinsEBold;
  font-size: 30px;
  color: #FFFFFF;
  text-align: right;
  position: absolute;
  bottom: 48px;
  right: 16px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;

}

</style>
<template>
  <v-app>
    <v-app-bar
        app color="#FFFFFF" height="130"
    >
      <v-card :class="$vuetify.breakpoint.mdAndUp ? 'logo' : 'logoCelular'" color="#FFFFFF" flat
              @click="$vuetify.goTo('#Intro')">
        <v-img
            max-height="71"
            max-width="143"
            src="./assets/logoDS.png"
        ></v-img>
      </v-card>

      <!--      MENU-->

      <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex justify-space-around menu">

        <!--        HOME-->

        <v-btn
            class="espaco"
            color="black"
            plain
            retain-focus-on-click
            text
            width="auto"
            @click="$vuetify.goTo('#Intro')"

        >
          <div class="sublinhado">
            QUEM SOMOS
          </div>

        </v-btn>

        <!--        O QUE FAZEMOS-->

        <v-btn class="espaco"
               color="black"
               plain
               retain-focus-on-click
               text
               width="auto"
               @click="$vuetify.goTo('#QFazemos')"
        >
          <div class="sublinhado">
            O QUE FAZEMOS
          </div>

        </v-btn>

        <!--        CURSOS-->

        <v-btn class="espaco"
               color="black"
               plain
               retain-focus-on-click
               text
               width="auto"
               @click="$vuetify.goTo('#Cursos')"
        >
          <div class="sublinhado">
            CURSOS
          </div>

        </v-btn>

        <!--        CLIENTES -->

        <v-btn class="espaco"
               color="black"
               plain
               retain-focus-on-click
               text
               width="auto"
               @click="$vuetify.goTo('#Clientes')"
        >
          <div class="sublinhado">
            CLIENTES
          </div>

        </v-btn>

        <!--        PACOTES-->

        <v-btn class="espaco"
               color="black"
               plain
               retain-focus-on-click
               text
               width="auto"
               @click="$vuetify.goTo('#Pacotes')"
        >
          <div class="sublinhado">
            PACOTES
          </div>

        </v-btn>

        <!--        POR ONDE ANDA NOSSO TRABALHO-->

        <v-btn class="espaco"
               color="black"
               plain
               retain-focus-on-click
               text
               width="auto"
               @click="$vuetify.goTo('#Andamos')"
        >
          <div class="sublinhado">
            POR ONDE ANDAMOS
          </div>

        </v-btn>

        <!--        CONTATO-->

        <v-btn class=""
               color="black"
               plain
               retain-focus-on-click
               text
               width="auto"
               @click="$vuetify.goTo('#Contato')"
        >
          <div class="sublinhado">
            CONTATO
          </div>

        </v-btn>

      </div>

    </v-app-bar>

    <v-btn
        class="float"
        icon
        text
        x-large
        @click="wapp"
    >
      <v-icon
          class="mx-auto"
          color="#fafafa"
          x-large>
        mdi-whatsapp
      </v-icon>
    </v-btn>

    <v-main class="mainOverflow">
      <div class="">
        <Intro id="Intro"/>
      </div>
      <div>
        <QFazemos id="QFazemos"/>
      </div>
      <div>
        <Cursos id="Cursos"/>
      </div>
      <div>
        <Clientes id="Clientes"/>
      </div>
      <div>
        <Pacotes v-if="$vuetify.breakpoint.mdAndUp" id="Pacotes"/>
        <Pacotes_md v-else id="Pacotes_md"/>
      </div>
      <div>
        <OAndamos id="Andamos"/>
      </div>
      <div>
        <Contato id="Contato" @enviarDados="receberDados"/>
      </div>
      <div>
        <Footer/>
      </div>

    </v-main>
  </v-app>
</template>

<script>
import Intro from './components/Intro.vue';
import QFazemos from './components/QFazemos.vue';
import Cursos from './components/Cursos.vue';
import Clientes from './components/Clientes.vue';
import Pacotes from './components/Pacotes.vue';
import Pacotes_md from './components/Pacotes_md.vue';
import OAndamos from './components/OAndamos.vue';
import Contato from './components/Contato.vue';
import Footer from './components/Footer.vue';
import {db} from "@/Firebase/firebase.js"
import {addDoc, collection, Timestamp} from "firebase/firestore";


export default {
  name: 'App',

  components: {
    Intro,
    QFazemos,
    Cursos,
    Clientes,
    Pacotes,
    Pacotes_md,
    OAndamos,
    Contato,
    Footer
  },

  data() {
    return {
      contato: {
        nome: "",
        empresa: "",
        email: "",
        telefone: "",
        msg: "",
        data: null,
      }
    };
  },
  methods: {

    wapp() {
      window.location.href = "https://wa.me/5521965878182/?text=Ol%C3%A1+Digital+Storm.+Vamos+conversar%21";
    },

    receberDados(dados) {
      Object.assign(this.contato, dados);

      this.enviarFirestore();
    },

    async enviarFirestore() {
      this.addData();
      await addDoc(collection(db, "contatos"), this.contato);
      console.log(this.contato);

    },
    addData() {
      this.contato.data = Timestamp.now();
    },

  },
};
</script>

<style>

@font-face {
  font-family: PoppinsReg;
  src: local("PoppinsReg"),
  url('./fonts/Poppins-Regular.woff') format('woff');
}

@font-face {
  font-family: PoppinsELight;
  src: local("PoppinsELight"),
  url('./fonts/Poppins-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: PoppinsEBold;
  src: local("PoppinsEBold"),
  url('./fonts/Poppins-ExtraBold.woff') format('woff');
}

/*::-webkit-scrollbar {*/
/*  display: none;*/
/*}*/
/*html, body{*/
/*  overflow-y: hidden;*/
/*}*/

/*.mainOverflow{*/
/*  overflow: hidden;*/
/*  border: 1px solid red;*/
/*},*/

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);


}

.logo {
  margin-left: 68px;
  margin-top: 15px;
}

.logoCelular {
  margin: auto;
}

.menu {
  margin-left: 100px;
  margin-top: 15px;
  font-family: PoppinsEBold, sans serif;
}

.sublinhado {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: #FFCE08;
  text-decoration-thickness: 3px;
  text-underline-offset: 3px;
}

.espaco {
  margin-right: 10px;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

</style>



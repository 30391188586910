<template>
  <div :class="$vuetify.breakpoint.mdAndUp ? 'altura_relativa' : 'altura_relativa_cel'">

    <div :class="$vuetify.breakpoint.mdAndUp ? 'textoContato text-center' : 'textoContatoCelular text-center'">
      <p>VAMOS CONVERSAR!</p>
    </div>

    <v-card color="transparent" flat>
      <v-img
          :class="$vuetify.breakpoint.mdAndUp ? 'altura_relativa' : 'altura_relativa_cel'"
          gradient="to top, rgba(242,251,1,.4), rgba(255,255,255,1)"
          src="../assets/fundo.png"

      >


        <v-card color="transparent" outlined>
          <v-container>
            <v-row>
              <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">

                <v-img class="imagem" src="../assets/contato.png">
                </v-img>


              </v-col>

              <v-col :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'">
                <v-card v-if="!agradecimento" :class="$vuetify.breakpoint.mdAndUp ? 'mt-12' : 'mt-n2'" color="transparent"
                        flat>
                  <v-text-field
                      v-model="nome"
                      color="#000000"
                      dense
                      label="Nome*"
                      outlined
                  ></v-text-field>
                  <v-text-field
                      v-model="empresa"
                      color="#000000"
                      dense
                      label="Empresa"
                      outlined
                  ></v-text-field>
                  <v-text-field
                      v-model="email"
                      color="#000000"
                      dense
                      label="Email*"
                      outlined
                  ></v-text-field>
                  <v-text-field
                      v-model="telefone"
                      color="#000000"
                      dense
                      label="Telefone*"
                      outlined
                  ></v-text-field>
                  <v-textarea
                      v-model="msg"
                      color="#000000"
                      height="90"
                      label="Mensagem"
                      outlined
                  ></v-textarea>
                  <div :class="$vuetify.breakpoint.mdAndUp ? '' : 'text-center'">

                    <v-btn
                        class="mt-n4"
                        color="#000000"
                        elevation="5"
                        height="30"
                        rounded
                        width="100"
                        @click="contactar">

                      <span class="white--text">Enviar!</span>

                    </v-btn>

                  </div>

                </v-card>
                <v-card v-if="agradecimento" color="#000000" flat>

                  <p class="text--white">Valeu</p>

                </v-card>
              </v-col>


            </v-row>

          </v-container>
        </v-card>

        <v-card color="transparent" outlined>

        </v-card>
      </v-img>


    </v-card>

  </div>
</template>

<script>
export default {
  name: "Contato.vue",
  data() {
    return {
      nome: "",
      empresa: "",
      email: "",
      telefone: "",
      msg: "",
      agradecimento: false,
    }
  },
  methods: {
    contactar() {
      console.log(this.nome);
      this.$emit("enviarDados", {
        nome: this.nome,
        empresa: this.empresa,
        email: this.email,
        telefone: this.telefone,
        msg: this.msg,
      });
      this.agradecimento = true;
    },
  },
}
</script>

<style scoped>

.altura_relativa {
  height: calc(100vh - 130px);
}

.altura_relativa_Cel {
  height: 150vh;
}

/*.bg {*/
/*  width: calc((100vw) / 1.9);*/
/*  !*height:auto;*!*/
/*}*/

.imagem {
  width: 90vw;
}


.textoContato {
  font-family: PoppinsEBold;
  font-size: 2rem;
  font-weight: lighter;
  color: #000000;
  margin-top: 50px;
  text-decoration-line: underline;
  text-decoration-style: double;
  text-decoration-color: #FFCE08;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
}

.textoContatoCelular {
  font-family: PoppinsELight;
  font-size: 2rem;
  color: #000000;
  margin-top: 50px;
  text-decoration-line: underline;
  text-decoration-style: double;
  text-decoration-color: #FFCE08;
  text-decoration-thickness: 3px;
}

.textoQuadrado {
  font-family: PoppinsReg;
  font-size: 30px;
  color: #FFFFFF;
  text-align: right;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;

}

.tituloQuadrado {
  font-family: PoppinsEBold;
  font-size: 30px;
  color: #FFFFFF;
  text-align: right;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;

}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
  color: rgba(0, 0, 0, 1);
}

.v-text-field--outlined fieldset {
  border-collapse: collapse;
  border-color: currentColor;
  border-style: solid;
  border-width: 2px;
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -5px;
  transition-duration: 0.15s;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}
</style>
<template>
  <div class="altura_relativa">
    <div :class="$vuetify.breakpoint.mdAndUp ? 'textoPacotes text-center' : 'textoPacotesCelular text-center'">
      <p>PACOTES</p>
    </div>

    <div class="d-flex justify-center mt-10" >
      <v-simple-table class="">
        <template v-slot:default>

          <thead class="">
          <tr>
            <th>
              Pacotes
            </th>
            <th>
              <v-tooltip bottom max-width="200">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="mb-4"
                      elevation=""
                      height="50"
                      rounded
                      text
                      v-bind="attrs"
                      width="200"
                      v-on="on">
                    <span class="textoBotao">BRONZE </span>
                  </v-btn>
                </template>
                <span>Nosso pacote mais básico, voltado a empresas que queiram ativar ou fortalecer sua
                  comunicação digital de forma profissional.</span>
              </v-tooltip>
            </th>
            <th>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="mb-4"
                      elevation=""
                      height="50"
                      rounded
                      text
                      v-bind="attrs"
                      width="200"
                      v-on="on">
                    <span class="textoBotao">PRATA </span>
                  </v-btn>
                </template>
                <span>Bottom tooltip</span>
              </v-tooltip>

            </th>
            <th>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="mb-4 ml-3"
                      elevation=""
                      height="50"
                      rounded
                      text
                      v-bind="attrs"
                      width="200"
                      v-on="on">
                    <span class="textoBotao"> OURO </span>
                  </v-btn>
                </template>
                <span>Bottom tooltip</span>
              </v-tooltip>
            </th>
          </tr>
          </thead>
          <thead>
          <tr>
            <th>
              <v-tooltip class="d-flex" max-width="250" right>
                <template v-slot:activator="{ on, attrs }">
                  <span
                      v-bind="attrs"
                      v-on="on"
                  >
              PRAZO SUGERIDO
                  </span>
                </template>
                <span>Período suficiente para que o cliente possa avaliar os benefícios atingidos e decidir se
                  pretende recontratar ou se já é hora de um plano maior.</span>
              </v-tooltip>

            </th>
            <th class="text-center">

              3 MESES
            </th>
            <th class="text-center">
              6 MESES
            </th>
            <th class="text-center">
              12 MESES
            </th>
          </tr>
          </thead>


          <tbody >
          <tr
              v-for="item in servicos"
              :key="item.name"
          >
            <td class="">{{ item.name }}

              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="mt-n6 ml-1"
                      color="primary"
                      small
                      v-bind="attrs"
                      v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>Tooltip</span>
              </v-tooltip>
            </td>

            <td v-if="item.icon1" class="text-center">
              <v-icon :color=item.colorIcon1> {{ item.icon1 }}</v-icon>
            </td>
            <td v-if="item.icon2" class="text-center">
              <v-icon :color=item.colorIcon2> {{ item.icon2 }}</v-icon>
            </td>
            <td v-if="item.icon3" class="text-center">
              <v-icon :color=item.colorIcon3>{{ item.icon3 }}</v-icon>
            </td>


            <td v-if="item.name1" class="text-center">
              <p> {{ item.name1 }}</p>
            </td>
            <td v-if="item.name2" class="text-center">
              <p> {{ item.name2 }}</p>
            </td>
            <td v-if="item.name3" class="text-center">
              <p class=""> {{ item.name3 }}</p>
            </td>

          </tr>
          </tbody>

        </template>
      </v-simple-table>
    </div>
  </div>

</template>

<script>
export default {
  name: "Pacotes.vue",
  data() {
    return {
      servicos: [
        {
          name: 'Planejamento de Comunicação',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-check-bold",
          colorIcon2: "green darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",

        },
        {
          name: 'Gestão das Redes Sociais',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-check-bold",
          colorIcon2: "green darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",
        },
        {
          name: 'Criação de Conteúdo:',
          name1: "Artes e textos",
          colorIcon1: "black",
          name2: "Fotos, artes e textos",
          colorIcon2: "black",
          name3: "Vídeos, fotos, artes e textos",
          colorIcon3: "black",
        },
        {
          name: 'Interação com público',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-close-thick",
          colorIcon2: "red darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",
        },
        {
          name: 'Performance',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-close-thick",
          colorIcon2: "red darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",
        },
        {
          name: 'Reuniões de acompanhamento',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-close-thick",
          colorIcon2: "red darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",
        },
        {
          name: 'Planejamento Estratégico',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-close-thick",
          colorIcon2: "red darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",
        },
        {
          name: 'Pesquisas:',
          name1: "Artes e textos",
          colorIcon1: "black",
          name2: "Fotos, artes e textos",
          colorIcon2: "black",
          name3: "Vídeos, fotos, artes e textos",
          colorIcon3: "black",
        },
        {
          name: 'Solução tecnológica para coleta de dados',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-close-thick",
          colorIcon2: "red darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",
        },
        {
          name: 'Análise de dados',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-close-thick",
          colorIcon2: "red darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",
        },
        {
          name: 'Business Intelligence',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-close-thick",
          colorIcon2: "red darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",
        },
        {
          name: 'Coleta de Leads',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-close-thick",
          colorIcon2: "red darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",
        },
        {
          name: 'Funil de Vendas',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-close-thick",
          colorIcon2: "red darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",
        },
      ],
    }
  },
}
</script>

<style scoped>
.altura_relativa {
  height: calc(150vh - 130px);
}

.botoes {
  display: flex;
}

.textoBotao {
  font-family: PoppinsEBold;
  font-size: 1.25rem;
  display: flex;
  margin-left: 0.5rem;
}

.infoIcon {
  margin-left: 2px;
}

.textoPacotes {
  font-family: PoppinsEBold;
  font-size: 2rem;
  font-weight: lighter;
  color: #000000;
  margin-top: 50px;
  text-decoration-line: underline;
  text-decoration-style: double;
  text-decoration-color: #FFCE08;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
}

.textoPacotesCelular {
  font-family: PoppinsELight;
  font-size: 2rem;
  color: #000000;
  margin-top: 50px;
  text-decoration-line: underline;
  text-decoration-style: double;
  text-decoration-color: #FFCE08;
  text-decoration-thickness: 3px;
}

/*.scrollTable {*/
/*  height: calc((100vh / 3) - 80px);*/
/*  margin: 0;*/
/*  width: 95%;*/
/*  overflow-y: auto;*/
/*}*/

/*thead, tbody{*/
/*  display: block;*/
/*}*/

/*tbody{*/
/*  overflow-y: scroll;*/
/*  height: 200px;*/
/*}*/

/*.v-data-table th,*/
/*.v-data-table td {*/
/*  padding: 0px;*/
/*  width:100%;*/
/*}*/


.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none;
}
.v-data-table > .v-data-table__wrapper > table {
  width: 80vw;
  border-spacing: 0;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: PoppinsEBold;
  font-size: 1.25rem;
  height: 48px;

  border-spacing: 0;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #000000;
}


.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0px 0px 15px 0px;
  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-family: PoppinsEBold;
  font-size: 1rem;
  height: 48px;
}


</style>
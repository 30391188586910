// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCiS-CX3jpxErzVcHEfOMX6ReetKnnCh4M",
    authDomain: "digitalstorm-bfb64.firebaseapp.com",
    projectId: "digitalstorm-bfb64",
    storageBucket: "digitalstorm-bfb64.appspot.com",
    messagingSenderId: "287307872693",
    appId: "1:287307872693:web:d2c7c570e17919dcb081fc"
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);


<template>

  <div :class="$vuetify.breakpoint.mdAndUp ? 'altura_relativa' : 'altura_relativa_Cel'">

    <div :class="$vuetify.breakpoint.mdAndUp ? 'textoCursos text-center' : 'textoCursosCelular text-center'">
      <p>CURSOS</p>
    </div>
    <v-container class="d-flex justify-center mt-n4">

      <v-card color="transparent" outlined>
        <v-container class="justify-space-between">
          <v-row>

            <!--PLANEJAMENTO ESTRATÉGICO-->
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card
                      :class="hover? 'altura_quadrado': 'altura_quadrado'"
                      :color="hover?'':'#FFCE08'"
                      :width="$vuetify.breakpoint.mdAndUp ? '320' : 'auto'">

                    <v-img v-if="!hover" class="altura_quadrado"
                           src="../assets/apoena.jpg"
                           width="auto">
                      <p v-if="!hover" :class="$vuetify.breakpoint.mdAndUp ? 'tituloCurso' : 'tituloCursoCel '">OFICINA
                        DE PLANEJAMENTO ESTRATÉGICO</p>
                      <p class="text-center">
                        <v-icon center class="mt-n5 ml-n1" color="#FFCE08"> mdi-information-outline</v-icon>
                      </p>
                    </v-img>

                    <p v-if="hover" class="tituloQuadradoHover pt-3">OFICINA DE PLANEJAMENTO</p>
                    <p v-if="hover" class="tituloQuadradoHover mt-n4">ESTRATÉGICO</p>


                    <v-card-text v-if="hover" class="altura_lista">

                      <p v-if="hover" class="textoQuadrado">
                        <strong>Professor: </strong> Apoena Ramos </p>
                      <p v-if="hover" class="textoQuadrado">
                        <strong>Sobre Apoena: </strong> Grande experiência na Indústria Criativa.
                        Frequentou diversos cursos ligados a estratégia, startups e cultura empreendedora no Brasil e
                        exterior. Atua na Digital Storm como desenvolvedor e no planejamento estratégico da agência.
                      </p>
                      <p v-if="hover" class="textoQuadrado">
                        <strong>Sobre a Oficina: </strong> Grande experiência na Indústria Criativa.
                        Frequentou diversos cursos ligados a estratégia, startups e cultura empreendedora no Brasil e
                        exterior. Atua na Digital Storm como desenvolvedor e no planejamento estratégico da agência.
                      </p>
                      <p v-if="hover" class="textoQuadrado">
                        Planejaremos juntos como ocupar estas posições estratégias e mantê-las por meio de um ciclo de
                        melhorias constante. Planejaremos juntos como ocupar estas posições estratégias e mantê-las por
                        meio de um ciclo de
                        melhorias constante. Planejaremos juntos como ocupar estas posições estratégias e mantê-las por
                        meio de um ciclo de
                        melhorias constante. Planejaremos juntos como ocupar estas posições estratégias e mantê-las por
                        meio de um ciclo de
                        melhorias constante. Planejaremos juntos como ocupar estas posições estratégias e mantê-las por
                        meio de um ciclo de
                        melhorias constante. Planejaremos juntos como ocupar estas posições estratégias e mantê-las por
                        meio de um ciclo de
                        melhorias constante. Planejaremos juntos como ocupar estas posições estratégias e mantê-las por
                        meio de um ciclo de
                      </p>
                      <p v-if="hover" class="textoQuadrado"></p>
                    </v-card-text>

                  </v-card>
                </template>
              </v-hover>

            </v-col>

            <!--INFLUENCER MKTG-->
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card
                      :class="hover? 'altura_quadrado': 'altura_quadrado'"
                      :color="hover?'':'#FFCE08'"
                      :width="$vuetify.breakpoint.mdAndUp ? '320' : 'auto'">

                    <v-img v-if="!hover" class="altura_quadrado"
                           src="../assets/natasha.jpg"
                           width="auto">
                      <p v-if="!hover" :class="$vuetify.breakpoint.mdAndUp ? 'tituloCurso' : 'tituloCursoCel '">OFICINA
                        DE PLANEJAMENTO ESTRATÉGICO</p>
                      <p class="text-center">
                        <v-icon center class="iconInfo mt-n5 ml-n1" color="#FFCE08"> mdi-information-outline</v-icon>
                      </p>
                    </v-img>

                    <p v-if="hover" class="tituloQuadradoHover pt-3">OFICINA DE PLANEJAMENTO</p>
                    <p v-if="hover" class="tituloQuadradoHover mt-n4">ESTRATÉGICO</p>


                    <v-card-text v-if="hover" class="altura_lista">

                      <p v-if="hover" class="textoQuadrado">
                        <strong>Professor: </strong> Apoena Ramos </p>
                      <p v-if="hover" class="textoQuadrado">
                        <strong>Sobre Apoena: </strong> Grande experiência na Indústria Criativa.
                        Frequentou diversos cursos ligados a estratégia, startups e cultura empreendedora no Brasil e
                        exterior. Atua na Digital Storm como desenvolvedor e no planejamento estratégico da agência.
                      </p>
                      <p v-if="hover" class="textoQuadrado">
                        <strong>Sobre a Oficina: </strong> Grande experiência na Indústria Criativa.
                        Frequentou diversos cursos ligados a estratégia, startups e cultura empreendedora no Brasil e
                        exterior. Atua na Digital Storm como desenvolvedor e no planejamento estratégico da agência.
                      </p>
                      <p v-if="hover" class="textoQuadrado">
                        Planejaremos juntos como ocupar estas posições estratégias e mantê-las por meio de um ciclo de
                        melhorias constante. Planejaremos juntos como ocupar estas posições estratégias e mantê-las por
                        meio de um ciclo de
                        melhorias constante. Planejaremos juntos como ocupar estas posições estratégias e mantê-las por
                        meio de um ciclo de
                        melhorias constante. Planejaremos juntos como ocupar estas posições estratégias e mantê-las por
                        meio de um ciclo de
                        melhorias constante. Planejaremos juntos como ocupar estas posições estratégias e mantê-las por
                        meio de um ciclo de
                        melhorias constante. Planejaremos juntos como ocupar estas posições estratégias e mantê-las por
                        meio de um ciclo de
                        melhorias constante. Planejaremos juntos como ocupar estas posições estratégias e mantê-las por
                        meio de um ciclo de
                      </p>
                    </v-card-text>

                  </v-card>
                </template>
              </v-hover>

            </v-col>

            <!--VIDEO MAKER-->
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card
                      :class="hover? 'altura_quadrado': 'altura_quadrado'"
                      :color="hover?'':'#FFCE08'"
                      :width="$vuetify.breakpoint.mdAndUp ? '320' : 'auto'">

                    <v-img v-if="!hover" class="altura_quadrado"
                           src="../assets/pit.jpg"
                           width="auto">
                      <p v-if="!hover" :class="$vuetify.breakpoint.mdAndUp ? 'tituloCurso' : 'tituloCursoCel '">OFICINA
                        DE PLANEJAMENTO ESTRATÉGICO</p>
                      <p class="text-center">
                        <v-icon center class="mt-n5 ml-n1" color="#FFCE08"> mdi-information-outline</v-icon>
                      </p>
                    </v-img>

                    <p v-if="hover" class="tituloQuadradoHover pt-3">OFICINA DE PLANEJAMENTO</p>
                    <p v-if="hover" class="tituloQuadradoHover mt-n4">ESTRATÉGICO</p>


                    <v-card-text v-if="hover" class="altura_lista">

                      <p v-if="hover" class="textoQuadrado">
                        <strong>Professor: </strong> Apoena Ramos </p>
                      <p v-if="hover" class="textoQuadrado">
                        <strong>Sobre Apoena: </strong> Grande experiência na Indústria Criativa.
                        Frequentou diversos cursos ligados a estratégia, startups e cultura empreendedora no Brasil e
                        exterior. Atua na Digital Storm como desenvolvedor e no planejamento estratégico da agência.
                      </p>
                      <p v-if="hover" class="textoQuadrado">
                        <strong>Sobre a Oficina: </strong> Grande experiência na Indústria Criativa.
                        Frequentou diversos cursos ligados a estratégia, startups e cultura empreendedora no Brasil e
                        exterior. Atua na Digital Storm como desenvolvedor e no planejamento estratégico da agência.
                      </p>
                      <p v-if="hover" class="textoQuadrado">
                        Planejaremos juntos como ocupar estas posições estratégias e mantê-las por meio de um ciclo de
                        melhorias constante. Planejaremos juntos como ocupar estas posições estratégias e mantê-las por
                        meio de um ciclo de
                        melhorias constante. Planejaremos juntos como ocupar estas posições estratégias e mantê-las por
                        meio de um ciclo de
                        melhorias constante. Planejaremos juntos como ocupar estas posições estratégias e mantê-las por
                        meio de um ciclo de
                        melhorias constante. Planejaremos juntos como ocupar estas posições estratégias e mantê-las por
                        meio de um ciclo de
                        melhorias constante. Planejaremos juntos como ocupar estas posições estratégias e mantê-las por
                        meio de um ciclo de
                        melhorias constante. Planejaremos juntos como ocupar estas posições estratégias e mantê-las por
                        meio de um ciclo de
                      </p>
                      <p v-if="hover" class="textoQuadrado"></p>
                    </v-card-text>

                  </v-card>
                </template>
              </v-hover>

            </v-col>

          </v-row>

          <v-row>


            <!--WAPP-->

            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="4"></v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
              <v-card
                  :width="$vuetify.breakpoint.mdAndUp ? '320' : 'auto'"
                  class="altura_quadrado pt-2"
                  color="#25D366"
                  elevation="20" @click="wapp">
                <v-img :class="$vuetify.breakpoint.mdAndUp ? 'icone' : 'icone_Cel'"
                       src="../assets/wapp.png"></v-img>
                <v-card-text>
                  <p class="tituloQuadrado">FALE CONOSCO!</p>
                  <p class="textoQuadrado text-center">Clique para saber sobre as próximas turmas, preço, formas de
                    pagamento ou
                    qualquer outra dúvida, fale conosco pelo Whatsapp!</p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="4"></v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>


<script>
export default {
  name: "QFazemos.vue",
  data: () => ({}),
  methods: {

    wapp() {
      window.location.href = "https://api.whatsapp.com/send?phone=7982325518&text=Olá, gostaria de saber mais sobre os cursos da Digital Storm!";
    },
  },
}

</script>

<style scoped>

.altura_relativa {
  height: calc(100vh - 130px);
}

.altura_relativa_Cel {
  height: 160vh;
}

.altura_quadrado {
  height: calc((100vh) / 3);
  border-radius: 15px;
  margin: 0;
  width: 90%;
}

.altura_quadrado_Wapp {
  height: calc((100vh) / 3);
  border-radius: 15px;
  margin: 0;
  width: 90%;
}

.altura_lista {
  height: calc((100vh / 3) - 80px);
  margin: 0;
  width: 95%;
  overflow-y: auto;
}


.icone {

  height: auto;
  margin: 10px auto auto;
  width: 30%;

}

.icone_Cel {

  height: auto;
  margin: auto;
  width: 25%;
}

.iconInfo {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
}

.textoCursos {
  font-family: PoppinsEBold;
  font-size: 2rem;
  font-weight: lighter;
  color: #000000;
  margin-top: 50px;
  text-decoration-line: underline;
  text-decoration-style: double;
  text-decoration-color: #FFCE08;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
}

.textoCursosCelular {
  font-family: PoppinsELight;
  font-size: 2rem;
  color: #000000;
  margin-top: 50px;
  text-decoration-line: underline;
  text-decoration-style: double;
  text-decoration-color: #FFCE08;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
}

.textoQuadrado {
  font-family: PoppinsReg;
  font-size: 0.8rem;
  color: #000000;
  line-height: 0.9rem;
  text-align: left;
}

.tituloCurso {
  font-family: PoppinsEBold;
  font-size: 1.5rem;
  margin-top: 150px;
  color: #FFCE08;
  line-height: 25px;
  text-align: center;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
}

.tituloCursoCel {
  font-family: PoppinsEBold;
  font-size: 1.5rem;
  margin-top: 30%;
  color: #FFCE08;
  line-height: 25px;
  text-align: center;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
}

.tituloQuadradoHover {
  font-family: PoppinsEBold;
  font-size: 15px;
  color: #000000;
  line-height: 20px;
  text-align: center;
}

.tituloQuadrado {
  font-family: PoppinsEBold;
  font-size: 25px;
  margin-top: 10px;
  color: #000000;
  line-height: 25px;
  text-align: center;
}

.scroll {
  overflow-y: scroll
}

</style>
<template>

  <v-card :class="$vuetify.breakpoint.mdAndUp ? 'altura' : 'altura_cel'" color="transparent">

    <v-card :class="$vuetify.breakpoint.mdAndUp ? 'largura_1' : ''" color="#FFCE08">

      <v-img
          v-if="!$vuetify.breakpoint.mdAndUp"
          class="altura_img_cel"
          src='../assets/novo.png'
      ></v-img>

      <v-card-text :class="$vuetify.breakpoint.mdAndUp ? 'text-left' : 'text-center'">
        <p :class="$vuetify.breakpoint.mdAndUp ? 'textoP1 mt-8 mx-6' : 'textoP1_Cel mt-0 mx-6'">Somos uma equipe de profissionais experientes com atuação em áreas
          complementares, que se reuniu para ser uma extensão do seu time.</p>
        <p :class="$vuetify.breakpoint.mdAndUp ? 'textoP2 mx-5' : 'textoP2_Cel mt-8 mx-5'">Da estratégia à execução, juntos fortaleceremos a posição da sua empresa no mundo
          digital!</p>

        <div class="text-center">
          <v-btn
              color="#000000"
              elevation="5"
              height="40"
              rounded
              width="200"
              @click="$vuetify.goTo('#QFazemos')">
            <span class="white--text">Vamos lá!</span>

          </v-btn>
        </div>


      </v-card-text>

    </v-card>
    <!--    <v-card class="bg">-->
    <v-card v-if="$vuetify.breakpoint.mdAndUp"  class="largura_2">
      <v-img
          class="altura_img"
          src='../assets/novo.png'
      >
        <v-card class="cartao" color="#FFCE08">
          <v-btn
              class="mr-5"
              icon
              text
              x-large
              @click="insta"
          >
            <v-icon
                color="#fafafa"
                x-large>
              mdi-instagram
            </v-icon>
          </v-btn>

          <v-btn
              class="mr-5"
              icon
              text
              x-large
              @click="fb">
            <v-icon
                color="#fafafa"
                x-large>
              mdi-facebook
            </v-icon>
          </v-btn>

          <v-btn
              class="mr-5"
              icon
              text
              x-large
              @click="$vuetify.goTo('#Contato')">
            <v-icon
                color="#fafafa"
                x-large>
              mdi-email-outline
            </v-icon>
          </v-btn>

        </v-card>
      </v-img>

    </v-card>


  </v-card>


</template>

<script>
export default {
  name: "Intro.vue",
  methods: {



    insta() {
      window.location.href = 'https://www.instagram.com/digitalstormbr/';
    },

    fb() {
      window.location.href = 'https://www.facebook.com/digitalstormrj';
    },
  }
}
</script>

<style scoped>

.altura_cel {
  height: calc(120vh - 130px);
  display: flex;
  justify-content: center;
}

.altura {
  height: calc(100vh - 130px);
  display: flex;
  justify-content: center;
}

.altura_relativa {
  height: calc(100vh - 130px);
}

.largura_1 {
  width: 30vw;
}
.largura_Cel{
  width: 100vw;
  display: flex;
  align-items: center;

}

.largura_2 {
  width: 70vw;
}

.altura_img {
  height: calc(100vh - 130px);
  display: flex;

}

.altura_img_cel {
  height: calc(50vh - 130px);
  display: flex;

}


.textoP1 {
  font-family: PoppinsReg;
  font-size: 25px;
  color: #000000;
  line-height: 30px;
}

.textoP2 {
  font-family: PoppinsEBold;
  font-size: 25px;
  color: #000000;
  line-height: 30px;
}

.textoP1_Cel{
  font-family: PoppinsReg;
  font-size: 20px;
  color: #000000;
  line-height: 30px;
}

.textoP2_Cel {
  font-family: PoppinsEBold;
  font-size: 20px;
  color: #000000;
  line-height: 30px;
}

.cartao {
  display: inline-flex;
  justify-content: center;
  width: 220px;
  margin-left: calc(70vw - 220px);
}

</style>
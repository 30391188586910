<template>

  <div :class="$vuetify.breakpoint.mdAndUp ? 'altura_relativa' : 'altura_relativa_Cel'">

    <div :class="$vuetify.breakpoint.mdAndUp ? 'textoFazemos text-center' : 'textoFazemosCelular text-center'">
      <p>O QUE FAZEMOS</p>
    </div>
    <v-container class="d-flex justify-center mt-n4">

      <v-card color="transparent" outlined>
        <v-container class="justify-space-between">
          <v-row>

            <!--PLANEJAMENTO ESTRATÉGICO-->
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card
                      :color="hover?'':'#FFCE08'"
                      :width="$vuetify.breakpoint.mdAndUp ? '320' : 'auto'"
                      class="altura_quadrado pt-2" elevation="20">

                    <v-img v-if="!hover" :class="$vuetify.breakpoint.mdAndUp ? 'icone' : 'icone_Cel'"
                           src="../assets/planejamento.png"></v-img>
                    <p :class="hover?'tituloQuadradoHover':'tituloQuadrado'">PLANEJAMENTO ESTRATÉGICO</p>
                    <p v-if="!hover" class="tituloQuadradoAcao mt-n2">
                      <v-icon class="mt-n1 ml-n1" color="black" right> mdi-information-outline</v-icon>
                    </p>

                    <v-card-text v-if="hover" class="altura_lista">
                      <p v-if="hover" class="textoQuadrado">
                        Uma empresa de sucesso é resultado de um processo de constante adequação ao mercado. </p>
                      <p v-if="hover" class="textoQuadrado">
                        Analisamos o cenário e concorrência, buscamos oportunidades, e segmentamos seu público em busca
                        de posições vantajosas e defensáveis. </p>
                      <p v-if="hover" class="textoQuadrado">
                        Planejaremos juntos como ocupar estas posições estratégias e mantê-las por meio de um ciclo de
                        melhorias constante.</p>
                      <p v-if="hover" class="textoQuadrado"></p>
                    </v-card-text>
                  </v-card>
                </template>
              </v-hover>

            </v-col>

            <!--BRANDING-->

            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card

                      :color="hover?'':'#FFCE08'"
                      :width="$vuetify.breakpoint.mdAndUp ? '320' : 'auto'"
                      class="altura_quadrado pt-2" elevation="20">

                    <v-img v-if="!hover" :class="$vuetify.breakpoint.mdAndUp ? 'icone' : 'icone_Cel'"
                           src="../assets/branding.png"></v-img>
                    <p :class="hover?'tituloQuadradoHover':'tituloQuadrado'">BRANDING</p>
                    <p v-if="!hover" class="tituloQuadradoAcao mt-n2">
                      <v-icon class="mt-n1 ml-n1" color="black" right> mdi-information-outline</v-icon>
                    </p>
                    <v-card-text v-if="hover" class="altura_lista">

                      <p v-if="hover" class="textoQuadrado">A relação entre clientes e empresas é hoje bidirecional e
                        dinâmica,
                        o Branding passou a refletir como os valores intangíveis da marca são percebidos por seu
                        público.</p>
                      <p v-if="hover" class="textoQuadrado">
                        A partir de pesquisas, personalizarmos sua estratégia de marca, desenvolveremos experiências de
                        relacionamento e ações para ganhar a lealdade de seus consumidores. </p>
                    </v-card-text>


                  </v-card>
                </template>
              </v-hover>

            </v-col>

            <!--REDES SOCIAIS-->

            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card

                      :color="hover?'':'#FFCE08'"
                      :width="$vuetify.breakpoint.mdAndUp ? '320' : 'auto'"
                      class="altura_quadrado pt-2" elevation="20">

                    <v-img v-if="!hover" :class="$vuetify.breakpoint.mdAndUp ? 'icone' : 'icone_Cel'"
                           src="../assets/midia-social.png"></v-img>
                    <p :class="hover?'tituloQuadradoHover':'tituloQuadrado'">REDES SOCIAIS</p>
                    <p v-if="!hover" class="tituloQuadradoAcao mt-n2">
                      <v-icon class="mt-n1 ml-n1" color="black" right> mdi-information-outline</v-icon>
                    </p>
                    <v-card-text v-if="hover" class="altura_lista">

                      <p v-if="hover" class="textoQuadrado">Ou sua empresa existe nas Redes Sociais ou não existe.</p>
                      <p v-if="hover" class="textoQuadrado">A partir de um planejamento de comunicação, ativaremos
                        suas redes cuidando desde a voz da marca até a gestão de crises.</p>
                      <p v-if="hover" class="textoQuadrado">Criamos conteúdos relevantes e o relacionamos organicamente
                        a sua marca (Inbound Marketing). São textos, imagens, vídeos e o que mais a gente inventar!</p>
                    </v-card-text>
                  </v-card>
                </template>
              </v-hover>
            </v-col>
          </v-row>

          <v-row>

            <!--CURSOS-->

            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card

                      :color="hover?'':'#FFCE08'"
                      :width="$vuetify.breakpoint.mdAndUp ? '320' : 'auto'"
                      class="altura_quadrado pt-2" elevation="20">

                    <v-img v-if="!hover" :class="$vuetify.breakpoint.mdAndUp ? 'icone' : 'icone_Cel'"
                           src="../assets/cursos.png"></v-img>
                    <p :class="hover?'tituloQuadradoHover':'tituloQuadrado'">CURSOS</p>
                    <p v-if="!hover" class="tituloQuadradoAcao mt-n2">
                      <v-icon class="mt-n1 ml-n1" color="black" right> mdi-information-outline</v-icon>
                    </p>
                    <v-card-text v-if="hover" class="altura_lista">

                      <p v-if="hover" class="textoQuadrado">A equipe da Digital Storm reúne várias expertises de
                        profissionais experimentados do mercado.</p>
                      <p v-if="hover" class="textoQuadrado">Compartilhamos este conhecimento através de cursos que
                        capacitam nossos clientes e suas equipes.</p>
                      <p v-if="hover" class="textoQuadrado">Veja mais na nossa seção de cursos!</p>


                    </v-card-text>
                  </v-card>
                </template>
              </v-hover>
            </v-col>

            <!--GROWTH-->

            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card

                      :color="hover?'':'#FFCE08'"
                      :width="$vuetify.breakpoint.mdAndUp ? '320' : 'auto'"
                      class="altura_quadrado pt-2" elevation="20">

                    <v-img v-if="!hover" :class="$vuetify.breakpoint.mdAndUp ? 'icone' : 'icone_Cel'"
                           src="../assets/diagram.png"></v-img>
                    <p :class="hover?'tituloQuadradoHover':'tituloQuadrado'">GROWTH MARKETING</p>
                    <p v-if="!hover" class="tituloQuadradoAcao mt-n2">
                      <v-icon class="mt-n1 ml-n1" color="black" right> mdi-information-outline</v-icon>
                    </p>
                    <v-card-text v-if="hover" class="altura_lista">

                      <p v-if="hover" class="textoQuadrado">Na Digital Storm fazemos mais que cuidar da sua presença
                        online. Nosso objetivo é aumentar sua taxa de conversão e, consequentemente, seu
                        faturamento!</p>
                      <p v-if="hover" class="textoQuadrado"> Nossa equipe multidisciplinar criará uma estratégia de
                        crescimento que envolve pesquisas,
                        marketing, vendas, tecnologia e testes constantes, para atingirmos metas críveis, definidas com
                        você e seu time.</p>
                      <p v-if="hover" class="textoQuadrado">Nós nos envolvemos no seu negócio, não queremos muitos
                        clientes, mas sim bons parceiros para
                        crescermos juntos.</p>
                    </v-card-text>
                  </v-card>
                </template>
              </v-hover>
            </v-col>

            <!--PESQUISAS-->

            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card

                      :color="hover?'':'#FFCE08'"
                      :width="$vuetify.breakpoint.mdAndUp ? '320' : 'auto'"
                      class="altura_quadrado pt-2" elevation="20">

                    <v-img v-if="!hover" :class="$vuetify.breakpoint.mdAndUp ? 'icone' : 'icone_Cel'"
                           src="../assets/pesquisa.png"></v-img>
                    <p :class="hover?'tituloQuadradoHover':'tituloQuadrado'">PESQUISAS</p>
                    <p v-if="!hover" class="tituloQuadradoAcao mt-n2">
                      <v-icon class="mt-n1 ml-n1" color="black" right> mdi-information-outline</v-icon>
                    </p>
                    <v-card-text v-if="hover" class="altura_lista">

                      <p v-if="hover" class="textoQuadrado">Medir é saber!</p>
                      <p v-if="hover" class="textoQuadrado"> Sem pesquisas constantes de público e mercado você navega
                        às cegas, desperdiça energia e recursos investindo em becos sem saída.</p>
                      <p v-if="hover" class="textoQuadrado">Oferecemos a tecnologia de coleta adequada ao seu caso e a
                        análise dos dados colhidos para pesquisas sobre o trade, a concorrência, seus segmentos de
                        clientes, a percepção da sua marca, etc.</p>
                    </v-card-text>
                  </v-card>
                </template>
              </v-hover>
            </v-col>

          </v-row>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>


<script>
export default {
  name: "QFazemos.vue"
}
</script>

<style scoped>

.altura_relativa {
  height: calc(100vh - 130px);
}

.altura_relativa_Cel {
  height: 235vh;
}

.altura_quadrado {
  height: calc((100vh) / 3);
  border-radius: 15px;
  margin: 0;
  width: 90%;
}

.altura_lista {
  height: calc((100vh / 3) - 35px);
  margin: 0;
  width: 95%;
  overflow-y: auto;
}

.icone {

  height: auto;
  margin: 10px auto auto;
  width: 30%;

}

.icone_Cel {

  height: auto;
  margin: auto;
  width: 25%;
}

.textoFazemos {
  font-family: PoppinsEBold;
  font-size: 2rem;
  font-weight: lighter;
  color: #000000;
  margin-top: 50px;
  text-decoration-line: underline;
  text-decoration-style: double;
  text-decoration-color: #FFCE08;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
}

.textoFazemosCelular {
  font-family: PoppinsELight;
  font-size: 2rem;
  color: #000000;
  margin-top: 50px;
  text-decoration-line: underline;
  text-decoration-style: double;
  text-decoration-color: #FFCE08;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
}

.textoQuadrado {
  font-family: PoppinsReg;
  font-size: 0.8rem;
  color: #000000;
  line-height: 0.9rem;
  text-align: left;
}

.tituloQuadrado {
  font-family: PoppinsEBold;
  font-size: 25px;
  margin-top: 30px;
  color: #000000;
  line-height: 25px;
  text-align: center;
}

.tituloQuadradoAcao {
  font-family: PoppinsEBold;
  font-size: 20px;
  margin-top: 30px;
  color: #000000;
  line-height: 25px;
  text-align: center;
}

.tituloQuadradoHover {
  font-family: PoppinsEBold;
  font-size: 15px;
  color: #000000;
  line-height: 10px;
  text-align: center;
}

</style>
<template>
  <div class="altura_relativa_Cel">
    <div class="textoPacotesCelular text-center">
      <p>PACOTES</p>
    </div>

    <v-card color="transparent" outlined>
      <v-container class="justify-space-between">
        <v-row>

          <!--BRONZE-->
          <v-col cols="12">
            <v-card class="altura_quadrado justify-center" elevation="20" width="auto">
              <v-toolbar
                  class="d-flex justify-center tituloPlano" color="#FFCE08" height="50">
                <p class="mt-5">BRONZE</p>
              </v-toolbar>
              <v-card-text class="text-center">
                <p class="textoQuadrado mt-n1">Analisamos o mercado e a concorrência em busca de posições vantajosas
                  para seus produtos. Mostramos como você pode alcançá-las e mantê-las.</p>
                <p class="textoQuadrado mt-n1"><strong>Prazo Sugerido:</strong> 3 Meses</p>
                <p class="textoQuadrado mt-n1"><strong>Serviços Inclusos:</strong></p>
              </v-card-text>
              <div class="mt-n5">
                <v-simple-table dense class="scrollTable text-center">
                  <template v-slot:default>

                    <tbody>
                    <v-divider class=""></v-divider>
                    <tr
                        v-for="item in servicos"
                        :key="item.name"
                    >

                      <td class="textoBotao">

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                class=""
                                color="primary"
                                small
                                v-bind="attrs"
                                v-on="on"
                            >
                              mdi-information-outline
                            </v-icon>
                          </template>
                          <span>Tooltip</span>

                        </v-tooltip>

                        <p class="mt-2 " >{{ item.name }}</p>
                      </td>
                      <v-divider class=""></v-divider>

                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-card>
          </v-col>

          <!--PRATA-->
          <v-col cols="12">
            <v-card class="altura_quadrado justify-center" elevation="20" width="auto">
              <v-toolbar
                  class="d-flex justify-center tituloPlano" color="#FFCE08" height="50">
                <p class="mt-5">PRATA</p>
              </v-toolbar>
              <v-card-text class="text-center">
                <p class="textoQuadrado mt-n1">Analisamos o mercado e a concorrência em busca de posições vantajosas
                  para seus produtos. Mostramos como você pode alcançá-las e mantê-las.</p>
                <p class="textoQuadrado mt-n1"><strong>Prazo Sugerido:</strong> 3 Meses</p>
                <p class="textoQuadrado mt-n1"><strong>Serviços Inclusos:</strong></p>
              </v-card-text>
              <div class="mt-n5">
                <v-simple-table dense class="scrollTable text-center">
                  <template v-slot:default>

                    <tbody>
                    <v-divider class=""></v-divider>
                    <tr
                        v-for="item in servicos"
                        :key="item.name"
                    >

                      <td class="textoBotao">

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                class=""
                                color="primary"
                                small
                                v-bind="attrs"
                                v-on="on"
                            >
                              mdi-information-outline
                            </v-icon>
                          </template>
                          <span>Tooltip</span>

                        </v-tooltip>

                        <p class="mt-2 " >{{ item.name }}</p>
                      </td>
                      <v-divider class=""></v-divider>

                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-card>
          </v-col>

          <!--OURO-->
          <v-col cols="12">
            <v-card class="altura_quadrado justify-center" elevation="20" width="auto">
              <v-toolbar
                  class="d-flex justify-center tituloPlano" color="#FFCE08" height="50">
                <p class="mt-5">OURO</p>
              </v-toolbar>
              <v-card-text class="text-center">
                <p class="textoQuadrado mt-n1">Analisamos o mercado e a concorrência em busca de posições vantajosas
                  para seus produtos. Mostramos como você pode alcançá-las e mantê-las.</p>
                <p class="textoQuadrado mt-n1"><strong>Prazo Sugerido:</strong> 3 Meses</p>
                <p class="textoQuadrado mt-n1"><strong>Serviços Inclusos:</strong></p>
              </v-card-text>
              <div class="mt-n5">
                <v-simple-table dense class="scrollTable text-center">
                  <template v-slot:default>

                    <tbody>
                    <v-divider class=""></v-divider>
                    <tr
                        v-for="item in servicos"
                        :key="item.name"
                    >

                      <td class="textoBotao">

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                class=""
                                color="primary"
                                small
                                v-bind="attrs"
                                v-on="on"
                            >
                              mdi-information-outline
                            </v-icon>
                          </template>
                          <span>Tooltip</span>

                        </v-tooltip>

                        <p class="mt-2 " >{{ item.name }}</p>
                      </td>
                      <v-divider class=""></v-divider>

                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-card>
          </v-col>


        </v-row>

      </v-container>
    </v-card>


  </div>

</template>

<script>
export default {
  name: "PacotesXS.vue",
  data() {
    return {
      completo: false,
      servicos: [
        {
          name: 'Planejamento de Comunicação',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-check-bold",
          colorIcon2: "green darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",

        },
        {
          name: 'Gestão das Redes Sociais',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-check-bold",
          colorIcon2: "green darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",
        },
        {
          name: 'Criação de Conteúdo:',
          name1: "Artes e textos",
          colorIcon1: "black",
          name2: "Fotos, artes e textos",
          colorIcon2: "black",
          name3: "Vídeos, fotos, artes e textos",
          colorIcon3: "black",
        },
        {
          name: 'Interação com público',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-close-thick",
          colorIcon2: "red darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",
        },
        {
          name: 'Performance',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-close-thick",
          colorIcon2: "red darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",
        },
        {
          name: 'Reuniões de acompanhamento',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-close-thick",
          colorIcon2: "red darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",
        },
        {
          name: 'Planejamento Estratégico',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-close-thick",
          colorIcon2: "red darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",
        },
        {
          name: 'Pesquisas:',
          name1: "Artes e textos",
          colorIcon1: "black",
          name2: "Fotos, artes e textos",
          colorIcon2: "black",
          name3: "Vídeos, fotos, artes e textos",
          colorIcon3: "black",
        },
        {
          name: 'Solução tecnológica para coleta de dados',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-close-thick",
          colorIcon2: "red darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",
        },
        {
          name: 'Análise de dados',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-close-thick",
          colorIcon2: "red darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",
        },
        {
          name: 'Business Intelligence',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-close-thick",
          colorIcon2: "red darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",
        },
        {
          name: 'Coleta de Leads',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-close-thick",
          colorIcon2: "red darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",
        },
        {
          name: 'Funil de Vendas',
          icon1: "mdi-check-bold",
          colorIcon1: "green darken-2",
          icon2: "mdi-close-thick",
          colorIcon2: "red darken-2",
          icon3: "mdi-check-bold",
          colorIcon3: "green darken-2",
        },
      ],
    }
  },
}
</script>

<style scoped>
.altura_relativa_Cel {
  height: calc(180vh - 130px);
}

.textoBotao {
  font-family: PoppinsEBold;
  font-size: 1.5rem;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin:1em;

}

.infoIcon {
  margin-left: 2px;
}

.scrollTable{
  height: calc((100vh / 3) - 80px);
  margin: 0;
  width: 100%;
  overflow-y: auto;
}


.textoPacotesCelular {
  font-family: PoppinsELight;
  font-size: 2rem;
  color: #000000;
  margin-top: 50px;
  text-decoration-line: underline;
  text-decoration-style: double;
  text-decoration-color: #FFCE08;
  text-decoration-thickness: 3px;
}

.textoQuadrado {
  font-family: PoppinsReg;
  font-size: 0.8rem;
  color: #000000;
  line-height: 0.9rem;
}

.tituloPlano {
  font-family: PoppinsEBold;
  font-size: 20px;
  color: #000000;
  line-height: 10px;
  align-items: center;
}


.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: PoppinsEBold;
  font-size: 1.5rem;

}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #000000;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  margin-left: auto;
  margin-right: auto;

}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-family: PoppinsEBold;
  font-size: 1rem;

}


</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'altura_relativa' : 'altura_relativa_Cel'},[_c('div',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'textoFazemos text-center' : 'textoFazemosCelular text-center'},[_c('p',[_vm._v("O QUE FAZEMOS")])]),_c('v-container',{staticClass:"d-flex justify-center mt-n4"},[_c('v-card',{attrs:{"color":"transparent","outlined":""}},[_c('v-container',{staticClass:"justify-space-between"},[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? '4' : '12'}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"altura_quadrado pt-2",attrs:{"color":hover?'':'#FFCE08',"width":_vm.$vuetify.breakpoint.mdAndUp ? '320' : 'auto',"elevation":"20"}},[(!hover)?_c('v-img',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'icone' : 'icone_Cel',attrs:{"src":require("../assets/planejamento.png")}}):_vm._e(),_c('p',{class:hover?'tituloQuadradoHover':'tituloQuadrado'},[_vm._v("PLANEJAMENTO ESTRATÉGICO")]),(!hover)?_c('p',{staticClass:"tituloQuadradoAcao mt-n2"},[_c('v-icon',{staticClass:"mt-n1 ml-n1",attrs:{"color":"black","right":""}},[_vm._v(" mdi-information-outline")])],1):_vm._e(),(hover)?_c('v-card-text',{staticClass:"altura_lista"},[(hover)?_c('p',{staticClass:"textoQuadrado"},[_vm._v(" Uma empresa de sucesso é resultado de um processo de constante adequação ao mercado. ")]):_vm._e(),(hover)?_c('p',{staticClass:"textoQuadrado"},[_vm._v(" Analisamos o cenário e concorrência, buscamos oportunidades, e segmentamos seu público em busca de posições vantajosas e defensáveis. ")]):_vm._e(),(hover)?_c('p',{staticClass:"textoQuadrado"},[_vm._v(" Planejaremos juntos como ocupar estas posições estratégias e mantê-las por meio de um ciclo de melhorias constante.")]):_vm._e(),(hover)?_c('p',{staticClass:"textoQuadrado"}):_vm._e()]):_vm._e()],1)]}}])})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? '4' : '12'}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"altura_quadrado pt-2",attrs:{"color":hover?'':'#FFCE08',"width":_vm.$vuetify.breakpoint.mdAndUp ? '320' : 'auto',"elevation":"20"}},[(!hover)?_c('v-img',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'icone' : 'icone_Cel',attrs:{"src":require("../assets/branding.png")}}):_vm._e(),_c('p',{class:hover?'tituloQuadradoHover':'tituloQuadrado'},[_vm._v("BRANDING")]),(!hover)?_c('p',{staticClass:"tituloQuadradoAcao mt-n2"},[_c('v-icon',{staticClass:"mt-n1 ml-n1",attrs:{"color":"black","right":""}},[_vm._v(" mdi-information-outline")])],1):_vm._e(),(hover)?_c('v-card-text',{staticClass:"altura_lista"},[(hover)?_c('p',{staticClass:"textoQuadrado"},[_vm._v("A relação entre clientes e empresas é hoje bidirecional e dinâmica, o Branding passou a refletir como os valores intangíveis da marca são percebidos por seu público.")]):_vm._e(),(hover)?_c('p',{staticClass:"textoQuadrado"},[_vm._v(" A partir de pesquisas, personalizarmos sua estratégia de marca, desenvolveremos experiências de relacionamento e ações para ganhar a lealdade de seus consumidores. ")]):_vm._e()]):_vm._e()],1)]}}])})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? '4' : '12'}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"altura_quadrado pt-2",attrs:{"color":hover?'':'#FFCE08',"width":_vm.$vuetify.breakpoint.mdAndUp ? '320' : 'auto',"elevation":"20"}},[(!hover)?_c('v-img',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'icone' : 'icone_Cel',attrs:{"src":require("../assets/midia-social.png")}}):_vm._e(),_c('p',{class:hover?'tituloQuadradoHover':'tituloQuadrado'},[_vm._v("REDES SOCIAIS")]),(!hover)?_c('p',{staticClass:"tituloQuadradoAcao mt-n2"},[_c('v-icon',{staticClass:"mt-n1 ml-n1",attrs:{"color":"black","right":""}},[_vm._v(" mdi-information-outline")])],1):_vm._e(),(hover)?_c('v-card-text',{staticClass:"altura_lista"},[(hover)?_c('p',{staticClass:"textoQuadrado"},[_vm._v("Ou sua empresa existe nas Redes Sociais ou não existe.")]):_vm._e(),(hover)?_c('p',{staticClass:"textoQuadrado"},[_vm._v("A partir de um planejamento de comunicação, ativaremos suas redes cuidando desde a voz da marca até a gestão de crises.")]):_vm._e(),(hover)?_c('p',{staticClass:"textoQuadrado"},[_vm._v("Criamos conteúdos relevantes e o relacionamos organicamente a sua marca (Inbound Marketing). São textos, imagens, vídeos e o que mais a gente inventar!")]):_vm._e()]):_vm._e()],1)]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? '4' : '12'}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"altura_quadrado pt-2",attrs:{"color":hover?'':'#FFCE08',"width":_vm.$vuetify.breakpoint.mdAndUp ? '320' : 'auto',"elevation":"20"}},[(!hover)?_c('v-img',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'icone' : 'icone_Cel',attrs:{"src":require("../assets/cursos.png")}}):_vm._e(),_c('p',{class:hover?'tituloQuadradoHover':'tituloQuadrado'},[_vm._v("CURSOS")]),(!hover)?_c('p',{staticClass:"tituloQuadradoAcao mt-n2"},[_c('v-icon',{staticClass:"mt-n1 ml-n1",attrs:{"color":"black","right":""}},[_vm._v(" mdi-information-outline")])],1):_vm._e(),(hover)?_c('v-card-text',{staticClass:"altura_lista"},[(hover)?_c('p',{staticClass:"textoQuadrado"},[_vm._v("A equipe da Digital Storm reúne várias expertises de profissionais experimentados do mercado.")]):_vm._e(),(hover)?_c('p',{staticClass:"textoQuadrado"},[_vm._v("Compartilhamos este conhecimento através de cursos que capacitam nossos clientes e suas equipes.")]):_vm._e(),(hover)?_c('p',{staticClass:"textoQuadrado"},[_vm._v("Veja mais na nossa seção de cursos!")]):_vm._e()]):_vm._e()],1)]}}])})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? '4' : '12'}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"altura_quadrado pt-2",attrs:{"color":hover?'':'#FFCE08',"width":_vm.$vuetify.breakpoint.mdAndUp ? '320' : 'auto',"elevation":"20"}},[(!hover)?_c('v-img',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'icone' : 'icone_Cel',attrs:{"src":require("../assets/diagram.png")}}):_vm._e(),_c('p',{class:hover?'tituloQuadradoHover':'tituloQuadrado'},[_vm._v("GROWTH MARKETING")]),(!hover)?_c('p',{staticClass:"tituloQuadradoAcao mt-n2"},[_c('v-icon',{staticClass:"mt-n1 ml-n1",attrs:{"color":"black","right":""}},[_vm._v(" mdi-information-outline")])],1):_vm._e(),(hover)?_c('v-card-text',{staticClass:"altura_lista"},[(hover)?_c('p',{staticClass:"textoQuadrado"},[_vm._v("Na Digital Storm fazemos mais que cuidar da sua presença online. Nosso objetivo é aumentar sua taxa de conversão e, consequentemente, seu faturamento!")]):_vm._e(),(hover)?_c('p',{staticClass:"textoQuadrado"},[_vm._v(" Nossa equipe multidisciplinar criará uma estratégia de crescimento que envolve pesquisas, marketing, vendas, tecnologia e testes constantes, para atingirmos metas críveis, definidas com você e seu time.")]):_vm._e(),(hover)?_c('p',{staticClass:"textoQuadrado"},[_vm._v("Nós nos envolvemos no seu negócio, não queremos muitos clientes, mas sim bons parceiros para crescermos juntos.")]):_vm._e()]):_vm._e()],1)]}}])})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? '4' : '12'}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"altura_quadrado pt-2",attrs:{"color":hover?'':'#FFCE08',"width":_vm.$vuetify.breakpoint.mdAndUp ? '320' : 'auto',"elevation":"20"}},[(!hover)?_c('v-img',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'icone' : 'icone_Cel',attrs:{"src":require("../assets/pesquisa.png")}}):_vm._e(),_c('p',{class:hover?'tituloQuadradoHover':'tituloQuadrado'},[_vm._v("PESQUISAS")]),(!hover)?_c('p',{staticClass:"tituloQuadradoAcao mt-n2"},[_c('v-icon',{staticClass:"mt-n1 ml-n1",attrs:{"color":"black","right":""}},[_vm._v(" mdi-information-outline")])],1):_vm._e(),(hover)?_c('v-card-text',{staticClass:"altura_lista"},[(hover)?_c('p',{staticClass:"textoQuadrado"},[_vm._v("Medir é saber!")]):_vm._e(),(hover)?_c('p',{staticClass:"textoQuadrado"},[_vm._v(" Sem pesquisas constantes de público e mercado você navega às cegas, desperdiça energia e recursos investindo em becos sem saída.")]):_vm._e(),(hover)?_c('p',{staticClass:"textoQuadrado"},[_vm._v("Oferecemos a tecnologia de coleta adequada ao seu caso e a análise dos dados colhidos para pesquisas sobre o trade, a concorrência, seus segmentos de clientes, a percepção da sua marca, etc.")]):_vm._e()]):_vm._e()],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"altura_relativa_Cel"},[_vm._m(0),_c('v-card',{attrs:{"color":"transparent","outlined":""}},[_c('v-container',{staticClass:"justify-space-between"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"altura_quadrado justify-center",attrs:{"elevation":"20","width":"auto"}},[_c('v-toolbar',{staticClass:"d-flex justify-center tituloPlano",attrs:{"color":"#FFCE08","height":"50"}},[_c('p',{staticClass:"mt-5"},[_vm._v("BRONZE")])]),_c('v-card-text',{staticClass:"text-center"},[_c('p',{staticClass:"textoQuadrado mt-n1"},[_vm._v("Analisamos o mercado e a concorrência em busca de posições vantajosas para seus produtos. Mostramos como você pode alcançá-las e mantê-las.")]),_c('p',{staticClass:"textoQuadrado mt-n1"},[_c('strong',[_vm._v("Prazo Sugerido:")]),_vm._v(" 3 Meses")]),_c('p',{staticClass:"textoQuadrado mt-n1"},[_c('strong',[_vm._v("Serviços Inclusos:")])])]),_c('div',{staticClass:"mt-n5"},[_c('v-simple-table',{staticClass:"scrollTable text-center",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('v-divider',{}),_vm._l((_vm.servicos),function(item){return _c('tr',{key:item.name},[_c('td',{staticClass:"textoBotao"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v("Tooltip")])]),_c('p',{staticClass:"mt-2 "},[_vm._v(_vm._s(item.name))])],1),_c('v-divider',{})],1)})],2)]},proxy:true}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"altura_quadrado justify-center",attrs:{"elevation":"20","width":"auto"}},[_c('v-toolbar',{staticClass:"d-flex justify-center tituloPlano",attrs:{"color":"#FFCE08","height":"50"}},[_c('p',{staticClass:"mt-5"},[_vm._v("PRATA")])]),_c('v-card-text',{staticClass:"text-center"},[_c('p',{staticClass:"textoQuadrado mt-n1"},[_vm._v("Analisamos o mercado e a concorrência em busca de posições vantajosas para seus produtos. Mostramos como você pode alcançá-las e mantê-las.")]),_c('p',{staticClass:"textoQuadrado mt-n1"},[_c('strong',[_vm._v("Prazo Sugerido:")]),_vm._v(" 3 Meses")]),_c('p',{staticClass:"textoQuadrado mt-n1"},[_c('strong',[_vm._v("Serviços Inclusos:")])])]),_c('div',{staticClass:"mt-n5"},[_c('v-simple-table',{staticClass:"scrollTable text-center",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('v-divider',{}),_vm._l((_vm.servicos),function(item){return _c('tr',{key:item.name},[_c('td',{staticClass:"textoBotao"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v("Tooltip")])]),_c('p',{staticClass:"mt-2 "},[_vm._v(_vm._s(item.name))])],1),_c('v-divider',{})],1)})],2)]},proxy:true}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"altura_quadrado justify-center",attrs:{"elevation":"20","width":"auto"}},[_c('v-toolbar',{staticClass:"d-flex justify-center tituloPlano",attrs:{"color":"#FFCE08","height":"50"}},[_c('p',{staticClass:"mt-5"},[_vm._v("OURO")])]),_c('v-card-text',{staticClass:"text-center"},[_c('p',{staticClass:"textoQuadrado mt-n1"},[_vm._v("Analisamos o mercado e a concorrência em busca de posições vantajosas para seus produtos. Mostramos como você pode alcançá-las e mantê-las.")]),_c('p',{staticClass:"textoQuadrado mt-n1"},[_c('strong',[_vm._v("Prazo Sugerido:")]),_vm._v(" 3 Meses")]),_c('p',{staticClass:"textoQuadrado mt-n1"},[_c('strong',[_vm._v("Serviços Inclusos:")])])]),_c('div',{staticClass:"mt-n5"},[_c('v-simple-table',{staticClass:"scrollTable text-center",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('v-divider',{}),_vm._l((_vm.servicos),function(item){return _c('tr',{key:item.name},[_c('td',{staticClass:"textoBotao"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v("Tooltip")])]),_c('p',{staticClass:"mt-2 "},[_vm._v(_vm._s(item.name))])],1),_c('v-divider',{})],1)})],2)]},proxy:true}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"textoPacotesCelular text-center"},[_c('p',[_vm._v("PACOTES")])])}]

export { render, staticRenderFns }